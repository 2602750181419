<template>
  <q-form ref="editForm">
    <div>
      <font class="text-primary" style="font-size:1em;font-weight:800;">
        ※ 교육과정 : {{ educationInfo.educationCourseName }}<br/>
        ※ 교육일 : {{ this.educationInfo.educationRangeFlag === 'Y' ? this.educationInfo.educationStartDate + ' ~ ' + this.educationInfo.educationEndDate : this.educationInfo.educationStartDate }}<br/>
      </font>
    </div>
    <c-table
      ref="table"
      isTitle
      gridHeightAuto
      title="교육대상자"
      :columns="grid.columns"
      :data="educationInfo.eduAttendeeList"
      :filtering="false"
      :checkClickFlag="false"
      :columnSetting="false"
      :merge="grid.merge"
      :usePaging="false"
      :hideBottom="true"
      :editable="editable && !disabled"
      :isExcelDown="false"
    >
      <!-- rowKey="attendeesNo"
      selection="multiple" -->
      <!-- <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
          <c-btn v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          <c-btn
            v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0"
            :url="saveUrl"
            :isSubmit="isSave1"
            :param="educationInfo.eduAttendeeList"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveEducation1"
            @btnCallback="saveCallback1" 
          />
        </q-btn-group>
      </template> -->
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'click'">
          <q-btn 
            round unelevated 
            size="10px"
            color="amber" 
            icon="edit"
            @click.stop="() => { electronSignature = props.row.electronSignature}"
            >
            <q-popup-proxy :ref="'proxy1_' + props.rowIndex" :breakpoint="400">
              <component
                :is="col.component"
                :popupParam="props.row"
                :rowIndex="props.rowIndex"
                @signCallback="signCallback"
              />
            </q-popup-proxy>
          </q-btn>
        </template>
        <!-- <template v-if="col.name === 'vodPlayTime'">
          <q-chip
            :color="getPlayTimeColor(props.row.vodTotalTime)"
            outline square
            :clickable="false"
            text-color="white">
            {{getPlayTime(props.row)}}
          </q-chip>
        </template> -->
      </template>
    </c-table>
    <c-card title="이수자 명단 파일" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'educationResultDetailAppr',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: null,
        eduEducationId: '',
        eduCourseId: '',
        isApprContent: true
      }),
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
        
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,

        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_COMPLETE_LIST',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:35%',
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자',
            style: 'width:35%',
            align: 'center',
            sortable: false
          },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            label: '이수<br/>여부',
            style: 'width:15%',
            align: 'center',
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명',
            style: 'width:15%',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'@/pages/sop/edu/result/signaturePop.vue'}`)
          },
        ],
        data: [],
        height: '400px'
      },
      electronSignature: '',
      editable: true,
      isSave1: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.educationInfo.documentStatusCd === 'ESC000010'
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.edu.result.get.url;
      this.saveUrl = transactionConfig.sop.edu.plan.insert.targetUser.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.targetUser.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.eduEducationId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.educationInfo, _result.data);

          this.$set(this.attachInfo, 'taskKey', this.educationInfo.eduEducationId)
        },);
      }
    },
    getPlayTime(_row) {
      if (_row.vodTotalTime === null) {
        return '시청 전';
      } else {
        let m1 = parseInt(_row.vodPlayTime / 60);
        let s1 = parseInt(_row.vodPlayTime % 60);
        let m2 = parseInt(_row.vodTotalTime / 60);
        let s2 = parseInt(_row.vodTotalTime % 60);
        return m1 + '분 ' + s1 + '초' + ' / ' + m2 + '분 ' + s2 + '초'
      }
    },
    getPlayTimeColor(_min) {
      if (_min === null) {
        return 'grey-6';
      } else {
        return 'blue'
      }
    },
    saveEducation1() {
      let invalidItemExists = false;
      invalidItemExists = this.$_.some(this.educationInfo.eduAttendeeList, _item => {
        return _item.checkCompleteFlag === 'Y' && (_item.evaluationDate === null || _item.evaluationDate === '');
      });
      if (!invalidItemExists) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.educationInfo.eduAttendeeList, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave1 = !this.isSave1;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '이수완료 처리한 대상자의 이수일을 입력하세요.', 
          type: 'warning', // success / info / warning / error
        });
      }
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.educationInfo.eduAttendeeList, item => {
        item.editFlag = 'U'
        if (item.electronSignature) item.electronSignatureFlag = '서명'
        else item.electronSignatureFlag = '미서명'
      })
      this.planUpdateBtnData.research = uid();
      // this.getAttendeeList();
      this.$emit('setData', this.educationInfo.eduEducationId)
    },
    addItem() {
      this.popupOptions.title = "교육대상자 검색"; // 교육대상자 검색
      this.popupOptions.param = {
        type: 'multiple',
        targetClassCd: this.targetClassCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationCompletePop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeTargetUserPopup;
    },
    closeTargetUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {
            attendeesId: _item.userId,
          });
          if (index === -1) {
            this.educationInfo.eduAttendeeList.splice(0, 0, {
              attendeesNo: uid(),
              attendeesTypeCd: 'EATC00003',
              attendeesDeptName: _item.deptName,
              attendeesPositionName: _item.jobName,
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              inExTypeCd: 'IN',
              eduEducationId: this.educationInfo.eduEducationId,
              seniorityName: _item.seniorName,
              checkCompleteFlag: null,
              electronSignature: '',
              evaluationPoint: null,
              evaluationContent: null,
              evaluationDate: null,
              attendanceTime: '',
              noAttendanceReason: '',
              opinionContent: '',
              checkCompleteFlagName: '미이수',
              electronSignatureFlag: '미서명',
              vodTotalTime: null,
              vodPlayTime: null,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },  
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.deleteUrl;
              this.$http.type = 'DELETE';
              this.$http.param = {
                data: Object.values(selectData)
              }
              this.$http.request(() => {
                this.$_.forEach(selectData, item => {
                  this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, { attendeesNo: item.attendeesNo })
                })
                this.planUpdateBtnData.research = uid();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    signCallback(data, rowIndex) {
      this.educationInfo.eduAttendeeList[rowIndex].electronSignature = data
      if(this.educationInfo.eduAttendeeList[rowIndex].editFlag !== 'C') {
        this.educationInfo.eduAttendeeList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
  }
};
</script>